
























































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import formatDate from '@/modules/common/filters/format-date.filter';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import Day from '@/modules/common/types/day.type';
import ClipText from '@/modules/common/filters/clip-text.filter';
import RatesPopupDemand from '@/modules/rates/components/rates-popup-demand.vue';
import RatesPopupOccupancy from '@/modules/rates/components/rates-popup-occupancy.vue';
import RatesScanBtn from '@/modules/common/modules/rates/components/rates-scan-btn.vue';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

import PromotionsService, { PromotionsServiceS } from '../promotions.service';

export interface ColumnData<T, B extends keyof T = keyof T> {
    label: string;
    field: B;
    width?: number | string;
    onHover?: (e: MouseEvent, data: T, col: ColumnData<T, B>) => void,
    onLeave?: (e: MouseEvent, data: T, col: ColumnData<T, B>) => void,

    /** Disabls v-text */
    noText?: boolean;

    component?: (value: T[B], item: T) => {
        is: string | Vue,
        [k: string]: any,
    },
}

export interface TableData {
    isCurrentHotel: boolean,
    isCompset: boolean,
}

@Component({
    filters: {
        ClipText: (value: string) => ClipText(value, 30),
    },
    components: {
        ModalWrapper,
        PopupEventsContainer,
        DayChanger,
        CompsetScale,
        RatesPopupDemand,
        RatesPopupOccupancy,
        RatesScanBtn,
        ScanBtnSmall,
    },
})
export default class RatesDayPopup<TableData = any> extends Vue {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    public showRawData = false;
    public baseClass = '';
    public isScanRequestPending = false;

    get day() {
        return +this.$route.params.day || 1;
    }

    get modalWidth() {
        return 880;
    }

    get tableData(): TableData[] {
        return [];
    }

    get isScanFeatureEnabled() {
        return this.promotionsService.isScanFeatureEnabled;
    }

    get isScanDisabled() {
        return this.documentFiltersService.isPreviousDay(this.day as Day)
            || this.documentFiltersService.isPreviousMonth
            || this.isScanRequestPending
            || !this.isScanFeatureEnabled;
    }

    get isScanning() {
        return this.promotionsService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get lastScanDate() {
        const { data } = this.promotionsService;
        if (!data || !data.scanDateByDay || !data.scanDateByDay[this.day]) return '--/--/--';
        return formatDate(data.scanDateByDay[this.day]);
    }

    get mainHotelId() {
        return +this.$route.params.hotelId;
    }

    get isLoading() {
        return false;
    }

    get programs() {
        const { data, provider } = this.promotionsService;

        if (!data || !provider) return [];

        return data.promotions[provider]
            .map(program => this.promotionsService
                .getProgramLabel(provider, program));
    }

    get activePrograms() {
        const { provider } = this.promotionsService;

        if (!provider) return 0;

        return this.promotionsService.getActivePrograms(this.day, provider, this.mainHotelId).length;
    }

    get deals() {
        const { provider } = this.promotionsService;

        if (!provider) return 0;

        return this.promotionsService.getDealsPrograms(this.day, provider, this.mainHotelId).length;
    }

    get averageCompsetPrograms() {
        const { day } = this;
        const { provider, competitors } = this.promotionsService;

        if (!provider) return 0;

        const avgCount = competitors
            .map(hotelId => this.promotionsService.getActivePrograms(day, provider, hotelId).length)
            .reduce((prev, curr) => prev + curr, 0) / competitors.length;

        return Math.round(avgCount);
    }

    get averageDeals() {
        const { day } = this;
        const { provider, competitors } = this.promotionsService;

        if (!provider) return 0;

        const avgCount = competitors
            .map(hotelId => this.promotionsService.getDealsPrograms(day, provider, hotelId).length)
            .reduce((prev, curr) => prev + curr, 0) / competitors.length;

        return Math.round(avgCount);
    }

    get programIndicator() {
        if (this.activePrograms === this.averageCompsetPrograms) return 'yellow';

        return this.activePrograms > this.averageCompsetPrograms
            ? 'green'
            : 'red';
    }

    get rows() {
        const { data, provider } = this.promotionsService;

        if (!data || !provider) return [];

        return data.hotels.map(hotelId => {
            const hotelName = this.hotelsService
                .getHotelName(hotelId);

            const programs = this.promotionsService
                .getPrograms(this.day, provider, hotelId);

            const programsData = data.promotions[provider]
                .map(programKey => {
                    const program = programs ? programs[programKey] : [];

                    if (!program || !program.status) {
                        return false;
                    }

                    return program.percentage
                        ? program.percentage
                        : true;
                });

            return {
                isMainHotel: hotelId === this.mainHotelId,
                hotelName,
                link: this.promotionsService
                    .getPromotionLink(this.day, provider, hotelId),
                programsData,
            };
        });
    }

    get promotionsHistoryPopup() {
        const name = this.$route.name!.includes('.history')
            ? this.$route.name!
            : `${this.$route.name}.history`;

        return { name };
    }

    public async triggerScan() {
        this.isScanRequestPending = true;
        await this.promotionsService.triggerScan(this.day);

        this.isScanRequestPending = false;
    }
}

